.category-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding-left: 70px; */
  /* padding-right: 70px; */
  margin-left: -15px;
  margin-right: -15px;
}
@media only screen and (max-width: 1500px) {
  .category-section {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.category-section .category-item {
  margin-left: 5px;
  margin-right: 5px;
}

.category-item {
  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.category-item .category-title {
  z-index: 1;
  position: relative;
  margin: 40px 15px;
}
.category-item .category-title .bg-text {
  left: 50%;
  font-size: 50px;
  transform: translate(-50%, -40%);
}

.category-item h4 {
  font-size: 1.2rem;
}

.category-item.style-two {
  background: white;
  box-shadow: 10px 4px 60px rgba(214, 16, 132, 0.1);
}
.category-item.style-two:after,
.category-item.style-two:before {
  display: none;
}

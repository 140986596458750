.about-content {
  margin-left: 65px;
}
@media only screen and (max-width: 1199px) {
  .about-content {
    margin-left: 0;
  }
}
.about-content > i {
  color: #242424;
}

.feedback-active {
  margin-left: -15px;
  margin-right: -15px;
}

.feedback-item {
  z-index: 1;
  padding: 45px;
  background: white;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px rgba(121, 154, 161, 0.2);
}
@media only screen and (max-width: 375px) {
  .feedback-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.feedback-item .for-border {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.feedback-item:before,
.feedback-item:after,
.feedback-item .for-border:after,
.feedback-item .for-border:before {
  content: '';
  transition: 0.2s;
  position: absolute;
  background: #d82f84;
}
.feedback-item:before,
.feedback-item .for-border:before {
  width: 1px;
  height: 0;
}
.feedback-item:after,
.feedback-item .for-border:after {
  height: 1px;
  width: 0;
}
.feedback-item:before {
  left: 0;
  bottom: 0;
}
.feedback-item:after {
  top: 0;
  left: 0;
  transition-delay: 0.6s;
}
.feedback-item .for-border:before {
  top: 0;
  right: 0;
  transition-delay: 0.4s;
}
.feedback-item .for-border:after {
  right: 0;
  bottom: 0;
  transition-delay: 0.2s;
}
.feedback-item:hover:before {
  height: 100%;
  transition-delay: 0.6s;
}
.feedback-item:hover:after {
  width: 100%;
  transition-delay: 0s;
}
.feedback-item:hover .for-border:before {
  height: 100%;
  transition-delay: 0.2s;
}
.feedback-item:hover .for-border:after {
  width: 100%;
  transition-delay: 0.4s;
}
.feedback-item .quote-rating {
  margin-bottom: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feedback-item .quote-rating > i {
  font-size: 55px;
  color: #d82f84;
}
.feedback-item .quote-rating .rating {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedback-item .quote-rating .rating i {
  color: #ff8a3d;
  margin-right: 10px;
}
.feedback-item .quote-rating .rating span {
  font-weight: 600;
  color: #ff8a3d;
  font-family: 'Montserrat', sans-serif;
}

.author-description {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (max-width: 375px) {
  .author-description {
    display: block;
  }
}
.author-description > i {
  width: 60px;
  height: 60px;
  margin-right: 25px;
  border-radius: 50%;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  font-size: 3rem;
}

.author-description > img {
  width: 60px;
  height: 60px;
  margin-right: 25px;
  border-radius: 50%;
}

@media only screen and (max-width: 375px) {
  .author-description img {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.author-description h5 {
  font-size: 20px;
  margin-bottom: 0;
}

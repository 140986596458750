/* Contact Page */
.contact-info-item {
  text-align: center;
  background: #f9eaf2;
  margin-bottom: 30px;
  padding: 40px 20px 20px;
  height: calc(100% - 30px);
  border: 1px solid rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 767px) {
  .contact-info-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.contact-info-item > i {
  display: inline-block;
  font-size: 45px;
  margin-bottom: 25px;
  color: #d82f84;
  width: 100px;
  height: 100px;
  background: white;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 10px 4px 60px rgba(214, 185, 129, 0.2);
}
.contact-info-item p a {
  font-weight: 600;
  word-wrap: break-word;
}

.contact-page-map iframe {
  height: 785px;
}
@media only screen and (max-width: 1500px) {
  .contact-page-map iframe {
    height: 600px;
  }
}
@media only screen and (max-width: 1199px) {
  .contact-page-map iframe {
    height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-page-map iframe {
    height: 400px;
  }
}

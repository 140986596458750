.service-details-content-wrap h3 {
  font-weight: 600;
}

.service-details-content-wrap p.lead {
  font-family: 'Lato', sans-serif;
  font-style: italic;
  font-size: 1.5rem;
  color: black;
}
@media only screen and (max-width: 480px) {
  .service-details-content-wrap h3 {
    font-size: 25px;
  }
}

/* Next Prev Service */
.next-prev-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.next-prev-service .all-services {
  margin: 15px;
  font-size: 20px;
  color: #d82f84;
}
.next-prev-service .nps-item {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .next-prev-service .nps-item {
    flex-wrap: wrap;
  }
}
.next-prev-service .nps-item > a {
  width: 40%;
  max-width: 100px;
}
@media only screen and (max-width: 575px) {
  .next-prev-service .nps-item > a {
    width: 100%;
  }
}
.next-prev-service .nps-item > a img {
  margin-bottom: 0;
}
@media only screen and (max-width: 575px) {
  .next-prev-service .nps-item > a img {
    margin-bottom: 15px;
  }
}
.next-prev-service .nps-item.prev-item > a {
  margin-right: 25px;
}
.next-prev-service .nps-item.next-item {
  text-align: right;
  justify-content: end;
}
.next-prev-service .nps-item.next-item > a {
  margin-left: 25px;
}
@media only screen and (max-width: 575px) {
  .next-prev-service .nps-item .nps-content {
    order: 2;
  }
}
.next-prev-service .nps-item .nps-content h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.next-prev-service .nps-item .nps-content span {
  display: block;
  font-size: 14px;
}
.next-prev-service .nps-item .nps-content .read-more {
  font-size: 14px;
}

.service-item {
  position: relative;
  margin-bottom: 10px;
}
.service-item img {
  width: 100%;
  box-shadow: 10px 4px 60px rgba(214, 16, 132, 0.2);
}

.page-banner {
  height: 400px;
}

/*******************************************************/
/***************** 19. Pricing Section *****************/
/*******************************************************/
.pricing-wrap {
  margin-bottom: 30px;
  padding: 40px 50px 25px;
  border: 1px solid rgba(121, 154, 161, 0.2);
  background: white;
  box-shadow: 10px 4px 60px rgba(214, 16, 132, 0.1);
}
@media only screen and (max-width: 575px) {
  .pricing-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 375px) {
  .pricing-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.pricing-item {
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(121, 154, 161, 0.2);
}
@media only screen and (max-width: 480px) {
  .pricing-item {
    display: block;
  }
}

.pricing-content {
  width: 100%;
}
.pricing-content .pricing-title {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-content .pricing-title h5 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
@media only screen and (max-width: 375px) {
  .pricing-content .pricing-title h5 {
    font-size: 16px;
  }
}
.pricing-content .pricing-title .price {
  font-size: 24px;
  font-weight: 600;
  color: #d82f84;
  font-family: 'Montserrat', sans-serif;
}
@media only screen and (max-width: 375px) {
  .pricing-content .pricing-title .price {
    font-size: 16px;
  }
}
.pricing-content .pricing-title .price:before {
  content: '$';
}
.pricing-content p {
  margin-bottom: 0;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-image {
  padding-left: 5px;
  padding-right: 5px;
}

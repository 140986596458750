/*******************************************************/
/******************* 06. Header style ******************/
/*******************************************************/
.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
}

/* top bar */

.header-top {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
}
.header-top ul {
  display: block;
}
.header-top ul li {
  display: flex;
  align-items: center;
  position: relative;
}

.header-top ul li span {
  margin-right: 5px;
  text-transform: uppercase;
}
.header-top ul li i {
  margin-right: 5px;
}

@media only screen and (min-width: 768px) {
  .header-top ul {
    display: flex;
  }
  .header-top ul li:not(:first-child) {
    padding-left: 10px;
  }
  /* .header-top ul li:after {
    content: '';
    width: 22px;
    height: 22px;
    background: transparent;
    line-height: 22px;
    border-radius: 50%;
    text-align: center;
    margin-left: 33px;
    margin-right: 33px;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .header-top ul li:before {
    content: '';
    width: 7px;
    height: 7px;
    background: white;
    line-height: 7px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    opacity: 0.3;
    right: 40px;
    top: 10px;
  } */
}

.header-top ul li:last-child:after,
.header-top ul li:last-child:before {
  display: none;
}

.main-header .header-upper {
  z-index: 5;
  width: 100%;
  background: white;
  position: relative;
  padding-left: 55px;
  padding-right: 55px;
  transition: all 500ms ease;
}
@media only screen and (max-width: 1500px) {
  .main-header .header-upper {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-header .logo-outer {
  flex: none;
}
@media only screen and (max-width: 991px) {
  .main-header .logo-outer {
    display: none;
  }
}
.main-header .logo {
  z-index: 9;
  padding: 2px 0;
  position: relative;
  width: 180px;
}
.main-header.fixed-header .header-upper {
  top: 0;
  left: 0;
  position: fixed;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}

/* Menu Sticky */
@-webkit-keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}

.nav-outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-outer .menu-btn {
  margin-left: 75px;
}
@media only screen and (max-width: 991px) {
  .nav-outer .menu-btn {
    display: none;
  }
}

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
  .main-menu {
    width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .main-menu .mobile-logo {
    max-width: 230px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.collapse.show {
  -webkit-animation: fadeIn 1s ease-in;
  animation: fadeIn 1s ease-in;
}

@media only screen and (max-width: 991px) {
  .main-menu .collapse {
    overflow: auto;
  }
}
.main-menu .navbar-collapse {
  padding: 0px;
}
.main-menu .navbar-collapse > ul {
  display: flex;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse > ul {
    display: block;
    padding: 15px 0;
    background: #ffffff;
  }
  .main-menu .navbar-collapse > ul > li:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse {
    left: 0;
    width: 100%;
    padding: 0 15px;
    position: absolute;
  }
}
.main-menu .navbar-collapse li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33px 20px;
}
@media only screen and (max-width: 1500px) {
  .main-menu .navbar-collapse li {
    padding: 30px 15px;
  }
}
@media only screen and (max-width: 1199px) {
  .main-menu .navbar-collapse li {
    padding: 30px 10px;
  }
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li {
    display: block;
    padding: 0 20px;
    border-top: 1px solid #f3f3f3;
  }
}

.main-menu .navbar-collapse li a {
  position: relative;
  display: block;
  font-size: 18px;
  opacity: 1;
  font-weight: 500;
  color: #242424;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  transition: all 500ms ease;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li a {
    padding: 10px 10px;
    line-height: 22px;
  }
}
.main-menu .navbar-collapse li.current > a,
.main-menu .navbar-collapse li.current-menu-item > a {
  font-weight: 500;
}
.main-menu .navbar-collapse li li {
  border-top: 1px solid #f3f3f3;
}
.main-menu .navbar-collapse li li a {
  text-transform: capitalize;
}

/* mobile navbar */
.main-menu .navbar-header {
  display: none;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.main-menu .navbar-header .navbar-toggle {
  float: right;
  padding: 4px 0;
  cursor: pointer;
  background: transparent;
}
.main-menu .navbar-header .navbar-toggle .icon-bar {
  background: #696969;
  height: 2px;
  width: 30px;
  display: block;
  margin: 7px 0;
}

/* Menu Icons */
.menu-icons {
  display: flex;
  align-items: center;
}

/* Menu Sidebar */
.menu-sidebar {
  display: flex;
}

.menu-sidebar button,
.menu-sidebar a {
  white-space: nowrap;
  font-weight: normal;
  padding: 5px 12px;
}

.menu-sidebar button i {
  font-size: 2rem;
  margin: 0px;
  padding: 0px;
}

/*Header Three*/
.header-three .menu-sidebar .icon-bar,
.header-three .main-menu .navbar-toggle .icon-bar {
  background: #d82f84;
}
.header-three .header-top ul li i {
  color: #d82f84;
}

.slider-section {
  background: rgba(216, 47, 132, 0.1);
}

.slider-section .carousel-control-prev,
.slider-section .carousel-control-next {
  width: 50px;
  height: 50px;
  z-index: 2;
  opacity: 0;
  color: white;
  transition: 0.5s;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: calc(50% - 25px);
  background: transparent;
  font-size: 1.5rem;
}
.slider-section .carousel-control-prev {
  left: 70px;
}
@media only screen and (max-width: 991px) {
  .slider-section .carousel-control-prev {
    left: 15px;
  }
}
.slider-section .carousel-control-next {
  right: 70px;
}
@media only screen and (max-width: 991px) {
  .slider-section .carousel-control-next {
    right: 15px;
  }
}
.slider-section .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 50px;
  display: flex;
  transform: translate(-50%);
}
.slider-section .slick-dots li {
  width: 10px;
  height: 10px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.5s;
  border-radius: 50%;
  text-indent: 100px;
  background: transparent;
  border: 2px solid white;
  margin-left: 5px;
  margin-right: 5px;
}
.slider-section .slick-dots li button {
  background: transparent;
}
.slider-section .slick-dots li.slick-active {
  background: white;
}
.slider-section:hover .carousel-control-prev,
.slider-section:hover .carousel-control-next {
  opacity: 1;
}

.slider-single-item {
  z-index: 1;
  min-height: calc(100vh - 137px);
  text-align: center;
  align-items: center;
  position: relative;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 150px;
  z-index: 1;
  position: relative;
}
.slider-section .slider-single-item:first-child {
  background-image: url('./../../assets/images/slider/liked/slider-5.jpg');
}
.slider-section .slider-single-item:nth-child(2) {
  background-image: url('./../../assets/images/slider/liked/slider-1.jpg');
}
.slider-section .slider-single-item:nth-child(3) {
  background-image: url('./../../assets/images/slider/liked/slider-10.jpg');
}
.slider-section .slider-single-item:last-child {
  background-image: url('./../../assets/images/slider/liked/store.jpeg');
}

.slider-single-item * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.slider-single-item .container {
  max-width: 700px;
}

.slider-single-item h1 {
  color: #242424;
  font-size: 85px;
  line-height: 1.3;
  margin-bottom: 25px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  text-transform: capitalize;
  transition: 0.5s;
  transform: translate(-0%, -0%) scale(0);
  font-style: italic;
  font-family: 'Lato', sans-serif;
}

.slider-single-item h1 span.styled {
  padding: 10px 20px;
  background: rgba(36, 36, 36, 0.8);
}

.slider-single-item .slider-btn {
  margin-left: -10px;
  margin-right: -10px;
}
.slider-single-item .slider-btn .theme-btn {
  margin: 10px 10px 0;
  width: 60%;
}
.slider-single-item.slide {
  display: flex;
}

@media only screen and (min-width: 1200px) {
  .slider-single-item .carousel-caption.right {
    bottom: auto;
    top: 15%;
    left: auto;
    right: 10%;
  }
  .slider-single-item .carousel-caption.left {
    bottom: auto;
    top: 15%;
    right: auto;
    left: 10%;
  }

  .slider-single-item h1 {
    font-size: 90px;
    /* font-weight: 600; */
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-item .carousel-caption.right {
    bottom: auto;
    top: 15%;
    left: auto;
    right: 2%;
  }
  .slider-single-item .carousel-caption.left {
    bottom: auto;
    top: 15%;
    right: auto;
    left: 2%;
  }

  .slider-single-item .container {
    max-width: 500px;
  }

  .slider-single-item h1 {
    font-size: 80px;
  }

  .slider-single-item .slider-btn .theme-btn {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .slider-section .slider-single-item:first-child {
    background-image: url('./../../assets/images/slider/liked/slider-5-1.jpg');
  }
  .slider-section .slider-single-item:nth-child(2) {
    background-image: url('./../../assets/images/slider/liked/slider-1-1.jpg');
  }
  .slider-section .slider-single-item:nth-child(3) {
    background-image: url('./../../assets/images/slider/liked/slider-10-1.jpg');
  }
  .slider-section .slider-single-item:last-child {
    background-image: url('./../../assets/images/slider/liked/store.jpeg');
  }

  .slider-single-item .carousel-caption.right,
  .slider-single-item .carousel-caption.left {
    bottom: 35px;
    top: auto;
    left: 15%;
    right: 15%;
  }

  .slider-single-item h1 {
    font-size: 70px;
    color: white;
  }

  .slider-single-item .slider-btn .theme-btn {
    padding: 7px 15px;
  }
}

@media only screen and (max-width: 576px) {
  .slider-single-item h1 {
    font-size: 50px;
    color: white;
  }

  .slider-single-item .slider-btn .theme-btn {
    padding: 5px 10px;
  }
}

.slider-single-item.active .bg-text {
  transition-delay: 1s;
  transform: translate(0px, -58%) scale(1);
}

.slider-single-item.active h1 {
  transition-delay: 0.7s;
  transform: translate(0px, 0%) scale(1);
}

@media only screen and (max-width: 576px) {
  .slider-single-item .slider-btn .theme-btn {
    width: 100%;
  }
}

/*******************************************************/
/***************** 22. Services Section ****************/
/*******************************************************/
.service-item {
  text-align: center;
  position: relative;
  /* margin-bottom: 145px; */
  /* padding-bottom: 60px; */
}
.service-item img {
  width: 100%;
  border-radius: 25px;
}
.service-item .service-content {
  position: relative;
  width: 84%;
  left: 8%;
  /* top: 70%; */
  padding: 25px 10px;
  transition: 0.5s;
  background: white;
  box-shadow: 10px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transform: translateY(-30px);
}
.service-item .service-content > i {
  width: 70px;
  height: 70px;
  line-height: 70px;
  position: absolute;
  bottom: 100%;
  left: 0;
  color: white;
  font-size: 35px;
  background: #d6b981;
}
.service-item .service-content h5 {
  font-weight: 600;
  margin-bottom: 8px;
}

.service-item .service-content small {
  font-size: 12px;
  display: block;
}

.service-item .service-content .read-more {
  font-size: 14px;
}
.service-item:hover .service-content {
  transform: translateY(-70px);
}

.price-list {
  padding: 10px 5px;
  margin-top: 10px;
  border: none;
}

.price-list ul li {
  margin-bottom: 0px;
}

.price-list .title {
  font-size: 15px;
  margin-bottom: 0px;
  position: relative;
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  padding: 0 2px 0 0;
  color: black;
}

.price-list .price {
  font-size: 15px;
  font-weight: 600;
  color: #d82f84;
  font-family: 'Montserrat', sans-serif;
  display: table-cell;
  width: 1%;
  padding: 0 0 0 2px;
  white-space: nowrap;
}

.price-list .price::before {
  content: '$';
}

.price-list .price-dots {
  position: relative;
  display: table-cell;
  height: 3px;
  width: 98%;
  z-index: 1;
  background-image: radial-gradient(
    circle closest-side,
    #000000 99%,
    transparent 100%
  );
  background-position: 50% 75%;
  background-size: 5px 2px;
  background-repeat: repeat-x;
}

.h-350 {
  height: 125px;
}

.h-545 {
  height: 400px;
}

/*******************************************************/
/***************** 11. Feature Section *****************/
/*******************************************************/

.features-section-content .section-title {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.features-section-content .section-title h2 {
  text-transform: none;
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  font-style: italic;
  font-weight: 300;
}
@media only screen and (max-width: 375px) {
  .features-section-content .section-title h2 {
    font-size: 1.7rem;
  }
}

.features-leaf-right {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 15%;
  opacity: 0.5;
  text-align: center;
  vertical-align: middle;
  color: #0000004d;
}

.features-leaf-right span {
  position: absolute;
  top: 50%;
  right: 10%;
}

.features-leaf-left {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 15%;
  opacity: 0.5;
  text-align: center;
  vertical-align: middle;
  color: #0000004d;
}

.features-leaf-left span {
  position: absolute;
  top: 50%;
  left: 10%;
}

/*******************************************************/
/***************** 12. Service Style 1 ****************/
/*******************************************************/
.project-section .col-lg-3,
.project-section .col-sm-6 {
  padding-right: 5px;
  padding-left: 5px;
}

.project-item {
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  max-width: 100%;
  box-shadow: 10px 4px 60px rgba(214, 16, 132, 0.1);
}

.project-item img {
  width: 100%;
}

.project-item:hover .project-content {
  opacity: 1;
  transform: translate(0);
}

.project-content {
  position: absolute;
  bottom: 0px;
  opacity: 1;
  max-width: 100%;
  background: rgba(26, 24, 24, 0.37);
  padding: 10px 0px;
  width: calc(100% - 0px);
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  text-transform: uppercase;
}

.project-content h5 {
  margin: 0;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.project-content h5 a {
  color: white;
}

.project-btn {
  margin-top: -55px;
}

.h-290 {
  height: 290px;
}

/*******************************************************/
/***************** 12. Booking Hours ****************/
/*******************************************************/

.booking-hour-image {
  position: absolute;
  height: 100%;
  width: 50vw;
  left: 0;
  top: 0;
}
@media only screen and (max-width: 991px) {
  .booking-hour-image {
    position: relative;
    height: 400px;
    width: 100%;
    margin-bottom: 75px;
  }
}

.booking-hours-content {
  max-width: 475px;
  margin-left: auto;
  padding-left: 30px;
}
@media only screen and (max-width: 991px) {
  .booking-hours-content {
    margin-left: 0;
    padding-left: 0;
    max-width: none;
  }
}

.booking-hours li {
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(36, 36, 36, 0.15);
}
@media only screen and (max-width: 375px) {
  .booking-hours li {
    font-size: 16px;
  }
}
.booking-hours li .date {
  width: 33%;
}

.booking-bg-icon {
  position: absolute;
  right: 30px;
  bottom: 20px;
  color: black;
  z-index: -1;
  opacity: 0.03;
  line-height: 1;
  font-size: 25vw;
}

/*******************************************************/
/***************** 15. Booking Section *****************/
/*******************************************************/
.booking-section {
  padding-left: 55px;
  padding-right: 55px;
}
@media only screen and (max-width: 1500px) {
  .booking-section {
    padding-left: 0;
    padding-right: 0;
  }
}

.booking-item {
  display: flex;
  background: #f9f9f9;
  margin-bottom: 30px;
  height: calc(100% - 30px);
}
@media only screen and (max-width: 767px) {
  .booking-item {
    display: block;
  }
}
.booking-item .booking-image {
  width: 100%;
  min-height: 400px;
  background-size: cover;
  background-position: center;
}

.booking-item .booking-image.membership {
  background-image: url('./../assets/images/shapes/member-card-1.png');
}

@media only screen and (max-width: 576px) {
  .booking-item .booking-image.membership {
    background-image: url('./../assets/images/shapes/member-card-2.png');
  }
}

.booking-item .booking-image.gift {
  background-image: url('./../assets/images/shapes/gift-card-1.png');
}

@media only screen and (max-width: 576px) {
  .booking-item .booking-image.gift {
    background-image: url('./../assets/images/shapes/gift-card-2.png');
  }
}

.booking-item .booking-content {
  padding: 60px 20px;
  align-self: center;
  position: relative;
  width: 50%;
  flex: none;
  z-index: 1;
  min-width: 400px;
}
@media only screen and (max-width: 767px) {
  .booking-item .booking-content {
    width: 100%;
    min-width: auto;
  }
}
@media only screen and (max-width: 575px) {
  .booking-item .booking-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.booking-item .booking-content .bg-text {
  top: 60px;
  left: 70px;
  font-size: 85px;
}
@media only screen and (max-width: 575px) {
  .booking-item .booking-content .bg-text {
    font-size: 55px;
  }
}
.booking-item .booking-content > h3 {
  margin-bottom: 18px;
}
.booking-item .booking-content ul li {
  display: flex;
  margin-top: 25px;
}
.booking-item .booking-content ul li > i {
  color: #ffaf7b;
  margin: 10px 20px 0 0;
}
.booking-item .booking-content ul li h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.booking-item .booking-content ul li p {
  margin-bottom: 0;
}
.booking-item .booking-content ul li .theme-btn {
  font-size: 15px;
  margin-top: 30px;
  padding: 10px 25px;
}

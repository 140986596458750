/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Montserrat:wght@400;500;600;700&family=Open+Sans&display=swap');
/*******************************************************/
/******************* 01. Default Style *****************/
/*******************************************************/
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,500;0,900;1,100;1,300;1,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
}

body {
  color: #696969;
  background: white;
  font-weight: 400;
  line-height: 1.8;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
}

a {
  color: #696969;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
  text-decoration: none;
}
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}
a:hover {
  color: #d82f84;
}

.home-two a:hover {
  color: #799aa1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 12px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #242424;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white h1 a,
.text-white h2 a,
.text-white h3 a,
.text-white h4 a,
.text-white h5 a,
.text-white h6 a {
  color: white;
}

.text-black,
.text-black * {
  color: #242424;
}

h1 {
  font-size: 85px;
}

h2 {
  font-size: 45px;
}

h3 {
  line-height: 1.5;
  font-size: 30px;
}

h4 {
  line-height: 1.4;
  font-size: 27px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

header:after,
section:after,
footer:after {
  display: block;
  clear: both;
  content: '';
}

/*======= Input Styles =======*/
input,
select,
textarea,
.nice-select,
.form-control {
  width: 100%;
  height: auto;
  border-radius: 0;
  padding: 22px 30px;
  background-color: #fff;
  border: 1px solid rgba(36, 36, 36, 0.15);
}

.nice-select {
  font-size: 18px;
  line-height: 1.3;
}
.nice-select:after {
  height: 8px;
  width: 8px;
  right: 25px;
  border-color: #242424;
}
.nice-select .current {
  font-weight: 600;
  color: #242424;
  font-family: 'Montserrat', sans-serif;
}
.nice-select .list {
  width: 100%;
  border-radius: 0;
}

textarea {
  display: inherit;
  padding-top: 20px;
}

label {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 5px;
  color: #696969;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}
.form-group label {
  top: 20px;
  right: 30px;
  font-size: 16px;
  position: absolute;
}

input:focus,
button:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #cfdbf1;
}

select,
select.form-control,
input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder,
.form-control::-moz-placeholder {
  font-weight: 600;
  color: #242424;
  font-family: 'Montserrat', sans-serif;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  font-weight: 600;
  color: #242424;
  font-family: 'Montserrat', sans-serif;
}

input::placeholder,
select::placeholder,
textarea::placeholder,
.form-control::placeholder {
  font-weight: 600;
  color: #242424;
  font-family: 'Montserrat', sans-serif;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='checkbox'],
input[type='radio'] {
  height: auto;
  width: auto;
}

/*******************************************************/
/*****************  02. Common Classes *****************/
/*******************************************************/
.page-wrapper {
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-width: 300px;
  background: #ffffff;
}

.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
}

.small-gap {
  margin-left: -5px;
  margin-right: -5px;
}
.small-gap > div {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 375px) {
  .col-small {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/** Section Title style **/
.section-title {
  z-index: 1;
  position: relative;
}
.section-title .sub-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #d82f84;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
}
@media only screen and (max-width: 375px) {
  .section-title .sub-title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .section-title h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .section-title h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 375px) {
  .section-title h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 480px) {
  .section-title h2 br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .section-title h3 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .section-title h3 {
    font-size: 20px;
  }
}
.section-title .bg-image {
  position: absolute;
  max-width: 80%;
  left: 50%;
  top: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.text-white .bg-text {
  color: rgba(255, 255, 255, 0.1);
}

/* .home-one .section-title .sub-title {
  color: #333333;
} */
.home-one .section-title h2 {
  text-transform: uppercase;
}

.home-one .section-title h2.text-none {
  text-transform: none;
}

.bg-text {
  font-size: 180px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  line-height: 1;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transform: translate(-25px, -25%);
}
@media only screen and (max-width: 1199px) {
  .bg-text {
    font-size: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .bg-text {
    font-size: 100px;
  }
}
@media only screen and (max-width: 375px) {
  .bg-text {
    font-size: 75px;
  }
}

.text-center .bg-text {
  left: 50%;
  transform: translate(-50%, -25%);
}

.text-white .sub-title {
  color: white;
}

/** Button style **/
.theme-btn,
a.theme-btn,
button.theme-btn {
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
  transition: 0.5s;
  font-weight: 600;
  position: relative;
  text-align: center;
  padding: 13px 35px;
  align-items: center;
  display: inline-flex;
  color: #d82f84;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #d82f84;
  background: transparent;
}
.theme-btn i,
a.theme-btn i,
button.theme-btn i {
  font-size: 13px;
  transition: 0.5s;
  margin-left: 10px;
}
@media only screen and (max-width: 480px) {
  .theme-btn,
  a.theme-btn,
  button.theme-btn {
    font-size: 16px;
    padding: 12px 25px;
  }
}
.theme-btn:after,
.theme-btn:before,
a.theme-btn:after,
a.theme-btn:before,
button.theme-btn:after,
button.theme-btn:before {
  height: 100%;
  content: '';
  width: 0;
  top: 0;
  z-index: -1;
  transition: 0.75s;
  position: absolute;
  background: #d82f84;
}
.theme-btn:before,
a.theme-btn:before,
button.theme-btn:before {
  right: 0;
}
.theme-btn:after,
a.theme-btn:after,
button.theme-btn:after {
  left: 0;
}
.theme-btn:hover,
a.theme-btn:hover,
button.theme-btn:hover {
  color: white;
}
.theme-btn:hover:after,
.theme-btn:hover:before,
a.theme-btn:hover:after,
a.theme-btn:hover:before,
button.theme-btn:hover:after,
button.theme-btn:hover:before {
  width: 55%;
}
.theme-btn.style-two,
a.theme-btn.style-two {
  color: white;
  background: #d82f84;
}
.theme-btn.style-two:after,
.theme-btn.style-two:before,
a.theme-btn.style-two:after,
a.theme-btn.style-two:before {
  background: white;
}
.theme-btn.style-two:hover,
a.theme-btn.style-two:hover {
  color: #d82f84;
}
.theme-btn.style-three,
a.theme-btn.style-three {
  color: white;
  background: #799aa1;
  border-color: #799aa1;
}
.theme-btn.style-three:after,
.theme-btn.style-three:before,
a.theme-btn.style-three:after,
a.theme-btn.style-three:before {
  background: white;
}
.theme-btn.style-three:hover,
a.theme-btn.style-three:hover {
  color: #799aa1;
}
.theme-btn.style-four,
a.theme-btn.style-four {
  background: white;
  border-color: white;
  color: #d82f84;
}
.theme-btn.style-four:after,
.theme-btn.style-four:before,
a.theme-btn.style-four:after,
a.theme-btn.style-four:before {
  background: #d82f84;
}
.theme-btn.style-four:hover,
a.theme-btn.style-four:hover {
  color: white;
}
.theme-btn.style-five,
a.theme-btn.style-five {
  color: white;
  border-color: white;
}
.theme-btn.style-five:after,
.theme-btn.style-five:before,
a.theme-btn.style-five:after,
a.theme-btn.style-five:before {
  background: white;
}
.theme-btn.style-five:hover,
a.theme-btn.style-five:hover {
  color: #d82f84;
}
.theme-btn.style-six,
a.theme-btn.style-six {
  color: white;
  background: #d82f84;
  border-color: #d82f84;
}
.theme-btn.style-six:after,
.theme-btn.style-six:before,
a.theme-btn.style-six:after,
a.theme-btn.style-six:before {
  background: white;
}
.theme-btn.style-six:hover,
a.theme-btn.style-six:hover {
  color: #d82f84;
  border-color: #d82f84;
}
.theme-btn.style-seven,
a.theme-btn.style-seven {
  border-color: rgba(36, 36, 36, 0.15);
}
.theme-btn.style-seven:after,
.theme-btn.style-seven:before,
a.theme-btn.style-seven:after,
a.theme-btn.style-seven:before {
  background: #d82f84;
}
.theme-btn.style-seven:hover,
a.theme-btn.style-seven:hover {
  border-color: #d82f84;
}

/* Read More */
.read-more {
  color: #8a8a8a;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  display: inline-flex;
  text-transform: uppercase;
}
.read-more i {
  margin-left: 7px;
  transition: 0.5s;
}
.read-more:hover {
  color: #d82f84;
}
.read-more:hover i {
  margin-left: 10px;
}

/* List style One */
.list-style-one li {
  display: flex;
  margin-bottom: 15px;
}
.list-style-one li:before {
  flex: none;
  font-size: 12px;
  content: '\f633';
  font-weight: 600;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: #e8aac9;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-family: 'Bootstrap-icons';
  color: black;
}

/* List style Two */
.list-style-two li {
  display: flex;
  margin-bottom: 15px;
}
.list-style-two li:before {
  content: '\f109';
  margin-right: 12px;
  font-family: 'flaticon';
}

/* List style Three */
.list-style-three {
  margin-bottom: 35px;
}
.list-style-three li {
  display: flex;
  color: #888888;
  font-weight: 500;
  transition: 0.5s;
  margin-bottom: 8px;
}
.list-style-three li:before {
  float: left;
  content: '\f35a';
  margin: 1px 12px 0 0;
  font-family: 'Font Awesome 5 Free';
}
.list-style-three li:hover {
  color: #d82f84;
  text-decoration: underline;
}

/** Social Link One **/
.social-style-one {
  flex-wrap: wrap;
  display: inline-flex;
}
.social-style-one a {
  color: white;
  opacity: 0.6;
  margin-right: 15px;
  box-sizing: content-box;
  width: 50px;
  height: 50px;
  background: transparent;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.social-style-one a:last-child {
  margin-right: 0;
}
.social-style-one a:hover {
  opacity: 1;
  background: white;
  color: #d82f84;
}

/* Social Link Two */
.social-style-two {
  display: inline-flex;
}
.social-style-two a {
  margin: 0 35px 5px 0;
}

/* Social Link Three */
.social-style-three {
  display: inline-flex;
}
.social-style-three a {
  font-size: 15px;
  margin: 5px 10px 0 0;
  width: 35px;
  height: 35px;
  background: transparent;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(36, 36, 36, 0.15);
}
.social-style-three a:hover {
  color: white;
  background: #d82f84;
  border-color: #d82f84;
}
.social-style-three a:last-child {
  margin-right: 0;
}

/* Pagination */
.pagination {
  align-items: center;
}
.pagination li {
  margin: 14px 7px 0;
}
@media only screen and (max-width: 480px) {
  .pagination li {
    margin: 8px 4px 0;
  }
}
.pagination li a,
.pagination li .page-link {
  padding: 0;
  box-shadow: none;
  font-weight: 600;
  width: 50px;
  height: 50px;
  background: white;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  color: #d82f84;
  border: 1px solid #c4c4c4;
}
@media only screen and (max-width: 480px) {
  .pagination li a,
  .pagination li .page-link {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
}
.pagination li.disabled .page-link,
.pagination li:last-child .page-link {
  border-radius: 50%;
}
.pagination li:first-child {
  margin-left: 0;
}
.pagination li:last-child {
  margin-right: 0;
}
.pagination li.active .page-link,
.pagination li:hover:not(.disabled) .page-link {
  color: white;
  background: #d82f84;
  border-color: #d82f84;
}
.pagination li.dot {
  overflow: hidden;
  text-indent: 100px;
  width: 7px;
  height: 7px;
  background: #c4c4c4;
  line-height: 7px;
  border-radius: 50%;
  text-align: center;
}

/* Rating */
.rating {
  display: inline-flex;
}
.rating i {
  margin: 3px;
  font-size: 13px;
  color: #d82f84;
}

/*** Scroll Top style ***/
.scroll-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  width: 40px;
  height: 40px;
  color: white;
  display: none;
  cursor: pointer;
  border-radius: 5px;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  background: #383838;
}

/* Text White */
.text-white *,
.text-white a {
  color: white;
}
.text-white * li li .dropdown-btn span,
.text-white a li li .dropdown-btn span {
  color: #d82f84;
}

/* Video Play Btn */
.video-play {
  top: 50%;
  left: 50%;
  z-index: 5;
  font-size: 22px;
  position: absolute;
  width: 115px;
  height: 115px;
  background: white;
  line-height: 115px;
  border-radius: 50%;
  text-align: center;
  color: #799aa1;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 480px) {
  .video-play {
    width: 75px;
    height: 75px;
    line-height: 75px;
  }
}

/* Position */
.rel {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.overlay {
  z-index: 1;
  position: relative;
}
.overlay::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.5;
  background-color: #242424;
}

/* Backgruond Size */
.bgs-cover {
  background-size: cover;
  background-position: center;
}

/* Color + Background */
.color-butter {
  color: #f6e9e4;
}

.color-black {
  color: #242424;
}

.bg-butter {
  background-color: #f6e9e4;
}

.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #242424;
}

.bg-pink {
  background-color: rgba(216, 47, 132, 0.15);
}

.bg-light-pink {
  background-color: rgba(216, 47, 132, 0.06);
}
.bg-light-pink-2 {
  background-color: #f9eaf2 !important;
}

.bg-light-gray {
  background-color: #f7f7f7;
}
.bg-light-gray-1 {
  background-color: #fdfdfd;
}

.bg-green {
  background-color: #799aa1;
}

/* .bg-pink {
  background-color: #d82f84;
} */

.color-pink {
  color: #d82f84;
}

.box-shadow-pink {
  box-shadow: 10px 4px 60px rgba(214, 16, 132, 0.2);
}

/* Border Radius */
.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 15px;
}

.br-20 {
  border-radius: 20px;
}

.br-25 {
  border-radius: 25px;
}

.br-30 {
  border-radius: 30px;
}

.error-inner h2 {
  font-size: 42px;
  font-weight: 600;
  margin: 75px 0 45px;
}
@media only screen and (max-width: 1199px) {
  .error-inner h2 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 480px) {
  .error-inner h2 {
    font-size: 30px;
  }
}

.home-three .read-more {
  font-size: 18px;
  text-decoration: underline;
}

/*******************************************************/
/******************* 03. Repeat Style ******************/
/*******************************************************/
.news-item .news-header .date span,
.feedback-content-item,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  color: #242424;
  font-family: 'Lato', sans-serif;
}

.bg-text {
  font-family: 'Dancing Script', cursive;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.05);
}

/*******************************************************/
/***************** 05. Custom Animation ****************/
/*******************************************************/
/* Animation Delay */
.delay-1-0s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-2-0s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.delay-0-1s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.delay-0-2s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.delay-0-4s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-0-6s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.delay-0-8s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.delay-1-8s {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay-1-9s {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

/* Menu Sticky */
/* @-webkit-keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
} */
/* Hero Circle */
@-webkit-keyframes rotated_circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotated_circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Hero Circle */
@-webkit-keyframes upDownLeft {
  0%,
  100% {
    transform: translate(0px, 0px);
  }
  25%,
  75% {
    transform: translate(0px, 50px);
  }
  50% {
    transform: translate(-50px, 50px);
  }
}
@keyframes upDownLeft {
  0%,
  100% {
    transform: translate(0px, 0px);
  }
  25%,
  75% {
    transform: translate(0px, 50px);
  }
  50% {
    transform: translate(-50px, 50px);
  }
}
@-webkit-keyframes shapeAnimationOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(0px, 150px) rotate(90deg);
  }
  50% {
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    transform: translate(150px, 0px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@keyframes shapeAnimationOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(0px, 150px) rotate(90deg);
  }
  50% {
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    transform: translate(150px, 0px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@-webkit-keyframes shapeAnimationTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-150px, 0px) rotate(270deg);
  }
  50% {
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    transform: translate(0px, -150px) rotate(90deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@keyframes shapeAnimationTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-150px, 0px) rotate(270deg);
  }
  50% {
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    transform: translate(0px, -150px) rotate(90deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@-webkit-keyframes shapeAnimationThree {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(50px, 150px) rotate(90deg);
  }
  50% {
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    transform: translate(150px, 50px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@keyframes shapeAnimationThree {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(50px, 150px) rotate(90deg);
  }
  50% {
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    transform: translate(150px, 50px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@-webkit-keyframes shapeAnimationFour {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-150px -50px) rotate(90deg);
  }
  50% {
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    transform: translate(-50px, -150px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@keyframes shapeAnimationFour {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-150px -50px) rotate(90deg);
  }
  50% {
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    transform: translate(-50px, -150px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@-webkit-keyframes shapeAnimationFive {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-100px -100px) rotate(90deg);
  }
  50% {
    transform: translate(100px, 50px) rotate(180deg);
  }
  75% {
    transform: translate(-100px, 150px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@keyframes shapeAnimationFive {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-100px -100px) rotate(90deg);
  }
  50% {
    transform: translate(100px, 50px) rotate(180deg);
  }
  75% {
    transform: translate(-100px, 150px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}
@-webkit-keyframes down-up-one {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(25px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
@keyframes down-up-one {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(25px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
@-webkit-keyframes down-up-two {
  0% {
    transform: rotateX(0deg) translate(0px);
  }
  50% {
    transform: rotateX(0deg) translate(25px, -25px);
  }
  100% {
    transform: rotateX(0deg) translate(0px);
  }
}
@keyframes down-up-two {
  0% {
    transform: rotateX(0deg) translate(0px);
  }
  50% {
    transform: rotateX(0deg) translate(25px, -25px);
  }
  100% {
    transform: rotateX(0deg) translate(0px);
  }
}
@-webkit-keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0);
  }
}

/*******************************************************/
/******************* 21. Page Banner *******************/
/*******************************************************/
.page-banner {
  z-index: 1;
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 450px;
}
.page-banner::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.4;
  background-color: black;
}

.banner-inner {
  position: relative;
}
.banner-inner h1 {
  text-transform: capitalize;
}
@media only screen and (max-width: 991px) {
  .banner-inner h1 {
    font-size: 65px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-inner h1 {
    font-size: 45px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-inner h1 {
    font-size: 38px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-inner h1 {
    font-size: 33px;
  }
}
@media only screen and (min-width: 1501px) {
  .banner-inner .bg-text {
    transform: translate(-50px, -50%);
  }
}
.banner-inner.text-center .bg-text {
  transform: translate(-50%, -50%);
}
.banner-inner.text-center .breadcrumb {
  justify-content: center;
}

.breadcrumb {
  margin: 0;
  padding: 0;
  font-size: 35px;
  background: transparent;
  text-transform: capitalize;
}
@media only screen and (max-width: 767px) {
  .breadcrumb {
    font-size: 25px;
  }
}
@media only screen and (max-width: 480px) {
  .breadcrumb {
    font-size: 20px;
  }
}
.breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadcrumb .breadcrumb-item.active {
  color: white;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  margin-left: 33px;
  margin-right: 33px;
  width: 10px;
  height: 10px;
  background: white;
  line-height: 10px;
  border-radius: 50%;
  text-align: center;
  content: '';
}
@media only screen and (max-width: 767px) {
  .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    margin-left: 15px;
    margin-right: 15px;
  }
}

/*******************************************************/
/***************** 25. Sidebar Widgets *****************/
/*******************************************************/
.blog-sidebar {
  max-width: 500px;
}

.widget {
  padding: 35px 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.widget.bg-light-pink-2 {
  padding: 35px 30px;
  margin-bottom: 30px;
  border: none;
}
@media only screen and (max-width: 375px) {
  .widget {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.widget:last-child {
  margin-bottom: 0;
}
.widget ul li {
  margin-bottom: 15px;
}
.widget ul li a,
.widget ul li p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget ul li a:hover {
  color: #242424;
}
.widget ul li:last-child {
  margin-bottom: 0;
}

/* Widget Title */
.widget-title {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(36, 36, 36, 0.15);
}
.widget-title:before {
  position: absolute;
  content: '';
  height: 3px;
  width: 50px;
  left: 0;
  bottom: -2px;
  background: #d82f84;
}

/* Form Widget */
.form-widget form .form-group {
  position: relative;
  margin-bottom: 10px;
}
.form-widget form .form-group label {
  position: absolute;
  right: 15px;
  top: 12px;
}
.form-widget form input,
.form-widget form select,
.form-widget form textarea,
.form-widget form .nice-select {
  border: none;
  padding: 13px 20px;
  background: #f7f7f7;
}

.bg-light-pink .form-widget form input,
.bg-light-pink .form-widget form select,
.bg-light-pink .form-widget form textarea,
.bg-light-pink .form-widget form .nice-select {
  border: none;
  padding: 13px 20px;
  background: #ffffff;
}
.form-widget form .nice-select {
  float: none;
  height: auto;
  font-size: 16px;
  line-height: 1.8;
  border-radius: 0;
}
.form-widget form .nice-select .current {
  font-weight: 600;
  color: #242424;
}
.form-widget form .nice-select .list {
  width: 100%;
  border-radius: 0;
}
.form-widget form .nice-select li {
  margin-bottom: 0;
}
.form-widget form .nice-select:after {
  width: 7px;
  height: 7px;
  right: 20px;
}

.widget-date-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #fff;
  /* width: 2.5rem; */
  /* height: 2.5rem; */
  border-radius: 20px;
  margin: 2px;
  padding: 10px 15px;
}

.widget-date-primary .date-day {
  color: #d82f84;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1;
}

.widget-date-primary .date-month {
  color: #d82f84;
  line-height: 1;
  font-size: 0.6rem;
  text-transform: uppercase;
}

/*******************************************************/
/******************* 24. FAQ Section *******************/
/*******************************************************/
.faq-accordion .card {
  border: none;
  font-size: 16px;
  border-radius: 0;
  margin-top: 10px;
  background: #f7f7f7;
}
.faq-accordion .card .card-header {
  border: none;
  display: flex;
  border-radius: 0;
  font-weight: 600;
  align-items: flex-start;
  padding: 12px 30px;
  color: #242424;
  text-decoration: underline;
  background-color: transparent;
  justify-content: space-between;
}
.faq-accordion .card .card-header i {
  float: right;
  margin-top: 8px;
  font-size: 14px;
  transition: 0.5s;
}
.faq-accordion .card .card-header.collapsed i {
  transform: rotate(-90deg);
}
.faq-accordion .card .card-header span {
  margin-right: 40px;
}

/* Useful Classes */
.navbar-collapse li a:active,
.navbar-collapse li a:hover,
.navbar-collapse li a.active {
  border-bottom: 2px solid #d82f84;
}
.pt-45 {
  padding-top: 45px;
}
.py-80 {
  padding: 80px 0px;
}

.py-150 {
  padding: 150px 0px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-120 {
  padding-top: 120px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-165 {
  padding-top: 165px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-130 {
  padding-bottom: 130px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-140 {
  margin-top: 140px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-140 {
  margin-bottom: 140px !important;
}
.my-145 {
  margin-top: 145px;
  margin-bottom: 145px;
}

@media only screen and (max-width: 786px) {
  .space-equalizer {
    height: 0px !important;
  }
}

@media only screen and (max-width: 375px) {
  .space-equalizer-sm {
    height: 0px !important;
  }
}

.with-errors {
  font-size: 1rem;
}

@media only screen and (min-width: 991px) {
  .left-15 {
    margin-left: -15%;
  }
}

.offer-card .img-fluid {
  height: 100%;
}

.career-section ul,
.career-section li {
  list-style-type: disc;
  margin-left: 20px;
}

.career-section ul li strong {
  color: #242424;
}

.booking-section {
  padding-left: 55px;
  padding-right: 55px;
}
@media only screen and (max-width: 1500px) {
  .booking-section {
    padding-left: 0;
    padding-right: 0;
  }
}

.booking-item {
  display: flex;
  background: #f9eaf2;
  margin: 30px 0px;
  border-radius: 20px;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .booking-item {
    display: block;
  }
}
.booking-item .booking-image {
  padding: 0px;
}

@media only screen and (min-width: 767px) {
  .booking-item .booking-image > img {
    min-height: 305px;
  }
}

.booking-item .booking-content {
  padding: 40px 20px;
  align-self: center;
  position: relative;
  z-index: 1;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .booking-item .booking-content {
    width: 100%;
    min-width: auto;
  }
}
@media only screen and (max-width: 575px) {
  .booking-item .booking-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.booking-item .booking-content > h1,
.booking-item .booking-content > h2 {
  margin-bottom: 18px;
  font-family: 'Dancing Script', cursive;
  text-transform: capitalize;
  color: #d82f84;
}

.booking-item .booking-content > h1 {
  font-size: 60px;
}

.booking-item .booking-content h3,
.booking-item .booking-content h4,
.booking-item .booking-content h5 {
  text-transform: capitalize;
}

.promotion-dates {
  display: flex;
  justify-content: center;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

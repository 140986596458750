.contact-form-wrap h2 {
  text-transform: none;
}

.contact-form-wrap {
  background: #f9f9f9;
  padding: 60px 80px;
}
@media only screen and (max-width: 991px) {
  .review-form-wrap,
  .contact-form-wrap {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .review-form-wrap,
  .contact-form-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.contact-form-wrap .rating i {
  color: rgba(0, 0, 0, 0.25);
}
.contact-form-wrap .form-control {
  border: none;
  box-shadow: 10px 4px 60px rgba(0, 0, 0, 0.1);
}

.contact-form-wrap select.form-control {
  border: none;
  box-shadow: 10px 4px 60px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  color: #242424;
  font-family: 'Montserrat', sans-serif;
}

.successful-appointment {
  background: #ecf7f0;
}

.successful-appointment p {
  padding: 10px 0px;
}

.successful-appointment p strong {
  color: black;
}

.successful-appointment h6 {
  cursor: pointer;
  color: #d82f84;
}

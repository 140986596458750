.main-footer {
  padding-top: 5rem !important;
}

.footer-title {
  margin-bottom: 35px;
}

.footer-widget {
  margin-bottom: 50px;
}
.footer-widget .social-style-one a i {
  transition: 0.5s;
}
.footer-widget .social-style-one a:hover i {
  color: #242424;
}

.menu-widget ul li {
  margin-bottom: 10px;
}
.menu-widget ul li:last-child {
  margin-bottom: 0;
}
.menu-widget ul li a {
  display: flex;
}
.menu-widget ul li a:before {
  content: '';
  width: 5px;
  height: 5px;
  background: white;
  line-height: 5px;
  border-radius: 50%;
  text-align: center;
  margin: 14px 10px 0 0;
}
.menu-widget ul li a:not(:hover):before {
  opacity: 0.6;
}
.copyright-area {
  flex-wrap: wrap;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.copyright-area .footer-menu {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  margin-bottom: 10px;
}
.copyright-area .footer-menu li {
  margin: 0 35px 5px 0;
}
@media only screen and (max-width: 1199px) {
  .copyright-area .footer-menu li {
    margin-right: 25px;
  }
}
.copyright-area .copyright-social p {
  margin-bottom: 8px;
}

.contact-widget ul li {
  display: flex;
  margin-bottom: 15px;
}
.contact-widget ul li i {
  flex: none;
  margin: -3px 15px 0 0;
  color: #d82f84;
}
.contact-widget ul li h5 {
  font-size: 20px;
  margin-bottom: 5px;
}

.contact-widget ul li hr {
  border: 0.5px dashed rgba(255, 255, 255, 0.6);
}

/* Footer Three */
.footer-three {
  font-size: 16px;
}
.footer-three .footer-title {
  font-size: 24px;
}
.footer-three a:not(:hover) {
  opacity: 0.6;
}
.footer-three a:hover {
  color: #d82f84;
  text-decoration: underline;
}
.footer-three p {
  opacity: 0.6;
}
.footer-three .menu-widget ul li a:before {
  background: #d82f84;
}
.footer-three .social-style-one a:hover {
  background: #d82f84;
}
.footer-three .social-style-one a {
  opacity: 1;
}
.footer-three .social-style-one a i {
  font-size: 1.1rem;
}
.footer-three .social-style-one a:hover i {
  color: white;
}
